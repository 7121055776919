export interface IAuthorize {
  authorize : boolean,
  duplicateSession : boolean
}

export const initialStateAuthorize: IAuthorize = {
	authorize : false,
  duplicateSession : false
};

