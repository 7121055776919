import {
	Component,
	ViewChild,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import { BcModalComponent } from "@bancolombia/design-system-web/bc-modal";
import { DataModal } from "src/assets/interfaces/interfaces.state";
import { DATA_MODAL } from "./constants";

@Component({
	selector: "app-modal",
	templateUrl: "./modal.component.html",
	styleUrls: ["./modal.component.scss"]
})
export class ModalComponent implements OnChanges {
	@ViewChild("modalAll", { static: false }) modalAll: BcModalComponent;

	clibtn1: boolean = false; //Boton 1 seleccionado
	clibtn2: boolean = false; //Boton 2 seleccionado
	clibtnCan: boolean = false; //Boton cancelar seleccionado


	@Input() botonPorDefecto: boolean = true; //No muestra el boton
	@Input() ejecu: boolean = false; // Abre el modal

	@Input() dataM: DataModal = DATA_MODAL; // Data por defecto
	@Input() customWidth: string = "small";
	@Input() titleAlign: string = "center";

	@Output() clickBtn1 = new EventEmitter<boolean>(); //Evento boton 1
	@Output() clickBtn2 = new EventEmitter<boolean>(); //Evento boton 2
	@Output() clickBtnCan = new EventEmitter<boolean>(); //Evento boton 2
  @Output() ejecuChange = new EventEmitter<boolean>();
	buttonAlignment = "vertical";


	ngOnChanges(changes: SimpleChanges): void {
    if(this.ejecu && changes.ejecu){
      if (changes.ejecu.currentValue) {
        this.openM();
      }
    }

	}

	openM() {
		this.modalAll.openModal();
	}

	fnBtnPrimary() {
		//Boton 1 click
		this.clickBtn1.emit(!this.clibtn1);
		if(this.dataM.closeModal){
      this.modalAll.shutDown();
    }
	}

	fnBtnSecundary() {
		//Boton 2 click
		this.clickBtn2.emit(!this.clibtn2);
		this.modalAll.shutDown();
	}

  fnBtnCancel() {
		//Boton cancelar click
		this.clickBtnCan.emit(!this.clibtnCan);
		this.modalAll.shutDown();
	}

  closeModalHideButton(){
		this.ejecuChange.emit(this.ejecu);
  }
}
