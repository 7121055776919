<app-navbar *ngIf="mostrarFooter"></app-navbar>
<router-outlet></router-outlet>
<app-footer *ngIf="mostrarFooter"></app-footer>
<app-moda-esp
	[dataM]="dataM"
	[ejecu]="openModalSessionExpire"
	[botonPorDefecto]="viewBtnExpire"
	(clickBtn1)="redirectToNegociarDeuda()"
></app-moda-esp>
<app-moda-esp
	[dataM]="dataM2"
	[ejecu]="openModalCuentaRegresiva"
  [closeEjecu]="closeModalInativity"
	[botonPorDefecto]="viewBtnCuentRegresiva"
	(clickBtn1)="cerrarModalCuentaRegresiva()"
></app-moda-esp>
<app-modal
  [dataM]="dataModal"
  [botonPorDefecto]="viewBtnModal"
  [ejecu]="duplicateSession"
  (clickBtn1)="logout2()"
>
</app-modal>
