import {
	Component,
	ViewChild,
	Input,
	Output,
	EventEmitter,
	SimpleChanges,
	OnInit
} from "@angular/core";
import { BcModalComponent } from "@bancolombia/design-system-web/bc-modal";
import { DataModalEsp } from "src/assets/interfaces/interfaces.state";
import { DATA_MODAL } from "./constants";

@Component({
	selector: "app-moda-esp",
	templateUrl: "./moda-esp.component.html",
	styleUrls: ["./moda-esp.component.scss"]
})
export class ModaEspComponent implements OnInit {
  @ViewChild("modalAll", { static: false }) modalAll: BcModalComponent;

	clibtn1: boolean = false; //Boton 1 seleccionado
	clibtn2: boolean = false; //Boton 2 seleccionado

	@Input() botonPorDefecto: boolean = true; //No muestra el boton
	@Input() ejecu: boolean = false; // Abre el modal
	@Input() closeEjecu: boolean = false; // Abre el modal

	@Input() dataM: DataModalEsp = DATA_MODAL; // Data por defecto
	@Input() customWidth: string = "small";
	@Input() titleAlign: string = "center";

	@Output() clickBtn1 = new EventEmitter<boolean>(); //Evento boton 1
	@Output() clickBtn2 = new EventEmitter<boolean>(); //Evento boton 2
	// @Output() clickBtnX = new EventEmitter<boolean>(); //Evento boton X

	buttonAlignment = "vertical";

	ngOnInit(): void {
    // This is intentional
  }

	ngOnChanges(changes: SimpleChanges): void {

    if(this.ejecu && changes.ejecu){

      if (changes.ejecu.currentValue) {
        this.openM();
      } else {
        this.modalAll.shutDown();
      }
    }

    if(this.closeEjecu && changes.closeEjecu){
      if (changes.closeEjecu.currentValue) {
        this.modalAll.shutDown();

      }
    }

	}

	openM() {
		this.modalAll.openModal();
	}

	fnBtnPrimary() {
		//Boton 1 click
		this.clickBtn1.emit(!this.clibtn1);
		this.modalAll.shutDown();
	}

	fnBtnSecundary() {
		//Boton 2 click
		this.clickBtn2.emit(!this.clibtn2);
		this.modalAll.shutDown();
	}
}
