import { Action } from "@ngrx/store";

export enum EAuthorize {
	LOAD_AUTHORIZE = "[Authorize] get Authorize Success",
  DUPLICATE_SESSION = "[DuplicateSession] Mark Duplicate Session"
}

export class LoadAuthorize implements Action {
	readonly type = EAuthorize.LOAD_AUTHORIZE;
	constructor(public payload: any) {}
}
export class DuplicateSession implements Action {
	readonly type = EAuthorize.DUPLICATE_SESSION;
	constructor(public payload: boolean) {}
}

export type AuthorizeAction =
	| LoadAuthorize
  | DuplicateSession;
