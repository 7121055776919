<div class="footer-area" *ngIf="hiddenNav">
	<div *ngIf="!dark" class="copyright-area">
		<div class="cont">
			<div class="row align-items-center mobile">
				<div class="bc-col foi">
					<div class="piac">
            <div>
              <bc-icon
              role="img"
              size="sm"
              aria-hidden="true"
              class="bc-header-brand-logo bc-sm bc-icon"
              tabindex="0">grupo-bancolombia</bc-icon>
						</div>
					</div>
				</div>

				<div class="bc-col text-end ff mobile-ip-vista">
					<!-- API Conect -->

					<p>
						Dirección IP {{(datax.ipClient !== null &&
						datax.ipClient !== undefined) ? datax.ipClient :
						(logout())}}
					</p>
					<span
						>Última visita
						<span class="capitalize">{{day}}</span></span
					>
					<span> {{dayNext}}</span>
					<span class="capitalize"> {{month}} </span>
					<span> {{monthNext}}</span>
					<span> {{timeHours}}</span>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="dark" class="copyright-area-dark">
		<div class="contDark">
			<div class="piacDark">
				<bc-icon
					class="iconDark"
					name="isotipo-bancolombia"
					aria-hidden="true"
					aria-label="Grupo Bancolombia"
				>
				</bc-icon>
				<span class="texto-logo">
					Copyright © 2024 Grupo Bancolombia
				</span>
			</div>
			<div class="claseDark"></div>
		</div>
	</div>

	<div *ngIf="dark" class="copyright-area-dark-web">
		<div class="banco">
			<bc-icon
				class="iconDarkweb"
				name="bancolombia-horizontal"
				aria-hidden="true"
				aria-label="Bancolombia"
			>
			</bc-icon>
		</div>
		<div class="claseDarkweb"></div>
		<div class="text">Copyright © 2024 Grupo Bancolombia</div>
    <div class="banco">
			<bc-icon
				class="iconDarkweb"
				name="grupo-bancolombia-horizontal"
				aria-hidden="true"
				aria-label="Bancolombia"
			>
			</bc-icon>
		</div>
	</div>
</div>
