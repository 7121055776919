<button
	bc-button
	[typeButton]=" dataM.typeButton"
	sizeButton="small"
	[ngStyle]="dataM.stylesBtn"
	(click)="openM()"
	*ngIf="botonPorDefecto"
>
	{{dataM.btnAbrirM}}
	<!-- texto de boton  poner ng if-->
</button>

<bc-modal
	#modalAll
	[title]=""
	[typeIcon]="false"
	[customWidth]="customWidth"
	[titleAlign]="titleAlign"
	[isCancellable]="false"
	[subTitle]=""
	[displayButton]="false"
	[buttonAlignment]="buttonAlignment"
	[textBtn]=""
	[textBtnLeft]=""
	typeNotify=""
	[hideCloseButton]="dataM.noVisibleX"
	[outerCloseTap]="false"
>
	<div modalContent>
		<div>
			<div class="contImTil">
				<div *ngIf="dataM.relo">
					<img
						src="./../../../../assets/img/figmaSVG/pic-clock.svg"
						alt="reloj"
						class="reloj"
					/>
				</div>
				<div>
					<!-- Titulo modal -->
					<h4>{{dataM.titule}}</h4>
					<!--  Texto 1 modal -->
					<p class="p1" [ngStyle]="dataM.styleText1">
						{{dataM.tex1}}
					</p>
					<p class="p1" [ngStyle]="dataM.styleText1Part2">
						{{dataM.tex1Part2}}
					</p>
					<p class="p1" [ngStyle]="dataM.styleText1Part3">
						{{dataM.tex1Part3}}
					</p>
					<!--  Texto 2 modal -->
					<p class="p2" [ngStyle]="dataM.styleText2" *ngIf="dataM.t2">
						{{dataM.tex2}}
					</p>
					<!--  Barra de progreso -->
          <bc-progress-bar
            id="bc-progress-bar"
            class="bc-progress-bar"
            type="lineal"
            [value]="dataM.slidersValue"
            color="yellow"
            background-color="light"
            *ngIf="dataM.progres"
          ></bc-progress-bar>
					<div class="botones">
						<div class="boton1">
							<button
								bc-button
								typeButton="primary"
								sizeButton="small"
								class="btn-primary-modal"
								(click)="fnBtnPrimary()"
								[routerLink]="dataM.pathBtn1 ? dataM.pathBtn1 : null"
							>
								{{dataM.texBtn1}}
							</button>
						</div>
						<div class="boton2" *ngIf="dataM.btn2">
							<button
								bc-button
								typeButton="secondary"
								sizeButton="small"
								class="btn-secundary-modal"
								(click)="fnBtnSecundary()"
							>
								{{dataM.texBtn2}}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</bc-modal>
