<section class="mt-5" style="background-color: #f4f4f4;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col col-xl-10">
        <div class="card contentCard">
          <div class="row g-0">
            <div class="col-md-6 col-lg-5 d-none d-md-block">
              <img src="https://www.bancolombia.com/wcm/connect/www.bancolombia.com-26918/1351b0ea-a29c-43bd-a29a-56957522239e/YDRAY-tabot-whatsapp+1.jpg?MOD=AJPERES&CACHEID=ROOTWORKSPACE.Z18_K9HC1202P864E0Q30449MS3000-1351b0ea-a29c-43bd-a29a-56957522239e-nzypemB"
                alt="login form" class="img-fluid contentImg"/>
            </div>
            <div class="col-md-6 col-lg-7 d-flex align-items-center">
              <div class="card-body p-4 p-lg-5 text-black">

                <form>

                  <div class="d-flex align-items-center mb-3 pb-1">
                    <em class="fas fa-cubes fa-2x me-3 color"></em>
                  </div>

                  <h5 class="fw-normal mb-3 pb-3 contentH5">Ingrese el documento</h5>

                  <div class="form-outline mb-4">
                    <input type="email" id="form2Example17"
                    class="form-control form-control-lg" (input)="idDocument = $event.target.value"/>
                    <label class="form-label" for="form2Example17">ID</label>
                  </div>

                  <div class="form-outline mb-4">
                    <input type="text" id="form2Example27" class="form-control form-control-lg"
                    (input)="typeDc = $event.target.value"/>
                    <label class="form-label" for="form2Example27" >Tipo de documento</label>
                  </div>

                  <div class="form-outline mb-4">
                    <bc-radio-group (changeValue)="onChangeValue($event)" id="banner-radio-group-origin" >
                      <bc-radio id="banner-radio-app" value="1">App</bc-radio>
                      <bc-radio id="banner-radio-portal" class="bc-ms-4" value="0">Portal</bc-radio>
                    </bc-radio-group>
                    <label class="form-label" for="banner-radio-group-origin" >Origen a simular</label>
                  </div>

                  <div class="pt-1 mb-4">
                    <button id="btn-simula-banner" class="btn btn-dark btn-lg btn-block" type="button" (click)="clickSimulacion()">Simula el banner</button>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
