import {
	INegotiationInfo,
	initialStateNegotiation
} from "../states/negotiation-info.state";
import { ENegotiationInfo, NegotiationAction } from "../actions/negotiation-info.action";

export function negotiationInfoReducer(
	state = initialStateNegotiation,
	action: NegotiationAction
): INegotiationInfo {
	switch (action.type) {
		case ENegotiationInfo.LOAD_NEGOTIATIONINFO_SUCCESS: {
			return {
				...state,
				data: action.payload
			};
		}
		case ENegotiationInfo.LOAD_NEGOTIATIONINFO_FAIL: {
			return {
				...state
			};
		}
		case ENegotiationInfo.UPDATE_DOCUMENT_STATE: {
			const { numberConsolidate, nameDocument, statusDocument } =
				action.payload;

			const updatedAlternatives = state.data.alternatives.map(
				(alternatives) => {
					return {
						...alternatives,
						dataAlternative: alternatives.dataAlternative.map(
							(data) => {
								if (
									data.numberConsolidate ===
										numberConsolidate &&
									data.documents
								) {
									const updatedDocuments = data.documents.map(
										(item) => {
											if (
												item.nameDocument ===
												nameDocument
											) {
												return {
													...item,
													statusDocument:
														statusDocument
												};
											}
											return item;
										}
									);
									return {
										...data,
										documents: updatedDocuments
									};
								}
								return data;
							}
						)
					};
				}
			);

			const updatedState = {
				...state,
				data: {
					...state.data,
					alternatives: updatedAlternatives
				}
			};
			return updatedState;
		}
		default:
			return state;
	}
}
